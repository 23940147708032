/* You can add global styles to this file, and also import other style files */

// Container for poppin  and all overlay container
// it helps the content to stay centered in the page
.custom-dialog {
  position: fixed !important;
  top: 5%;
  width: -webkit-fill-available;
  background-color: white;
  z-index: 100000;
  margin-right: 20vw;
  margin-left: 20vw;
  @media screen and (max-width: 600px) {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  @media screen and (max-width: 1380px) and (min-width: 600px) {
    margin-right: 10vw;
    margin-left: 10vw;
  }
}

// margin of a page content
.screen-margin {
  margin-right: 10rem;
  margin-left: 10rem;
  @media screen and (max-width: 1200px) {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

.warning {
  background-color: var(--warning) !important;
}
.success {
  background-color: var(--success) !important;
}
.danger {
  background-color: var(--danger) !important;
}
.disabled {
  background-color: var(--dark) !important;
}

:root {
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 4%);
  --light: hsl(0, 0%, 96%);
  --dark: #9a9a9a;
  --dark6: #8c8c8c;
  --dark5: #9a9a9a;
  --dark4: #bbbbbb;
  --dark3: #cccccc;
  --dark2: #dddddd;
  --dark1: #eeeeee;
  --primary: #9a1619;
  --secondary: #c06f2f;

  --link: hsl(217, 71%, 53%);
  --info: hsl(204, 86%, 53%);
  --success: #499c60;
  --warning: #fffda3;
  --danger: #d51e21;
  font-family: "Poppins", sans-serif;
}

// Container for poppin
.custom-dialog {
  position: fixed !important;
  top: 5%;
  width: -webkit-fill-available;
  background-color: white;
  z-index: 100000;
  margin-right: 20vw;
  margin-left: 20vw;
  @media screen and (max-width: 600px) {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  @media screen and (max-width: 1380px) and (min-width: 600px) {
    margin-right: 10vw;
    margin-left: 10vw;
  }
}

h1 {
  .warning {
    color: var(--danger) !important;
  }
  .success {
    color: var(--success) !important;
  }
  .danger {
    color: var(--success) !important;
  }
}
h2 {
  .warning {
    color: var(--danger) !important;
  }
  .success {
    color: var(--success) !important;
  }
  .danger {
    color: var(--success) !important;
  }
}
h3 {
  .warning {
    color: var(--danger) !important;
  }
  .success {
    color: var(--success) !important;
  }
  .danger {
    color: var(--success) !important;
  }
}
h4 {
  .warning {
    color: var(--danger) !important;
  }
  .success {
    color: var(--success) !important;
  }
  .danger {
    color: var(--success) !important;
  }
}
h5 {
  .warning {
    color: var(--danger);
  }
  .success {
    color: var(--success);
  }
  .danger {
    color: var(--success);
  }
}

.primary {
  background-color: var(--primary);
}
